<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="dialog-table dialog-wrap">
    <div
      class="dialog-table__box dialog-box__animation dialog-content__box"
      @click.stop
    >
      <slot name="title">
      </slot>
      <!-- 插槽 -->
      <div class="dialog-box__body">
        <template v-if="content.tableDesc || $slots.tableDesc">
          <slot name="tableDesc">
            <div
              v-if="Array.isArray(content.tableDesc)&&content.tableDesc.length"
              class="dialog-table__myself"
            >
              <div
                v-for="(item,index) in content.tableDesc"
                :key="index"
                class="dialog-table__myself-level"
              >
                {{ item.label }}：{{ item.value }}
              </div>
            </div>
            <div
              v-else
              v-html="DOMPurify.sanitize(content.tableDesc)"
            >
            </div>
          </slot>
        </template>
        <div class="dialog-table__content table-box">
          <div class="dialog-table__thead table-hd">
            <div class="dialog-table__tr table-tr">
              <div
                v-for="(item,index) in content?.tableHeaderConfig||[]"
                :key="index"
                class="dialog-table__th table-td"
              >
                <div
                  v-if="showHeader"
                  class="table-txt"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="dialog-table__tbody table-bd">
            <div
              v-for="(item,index) in content?.tableData||[]"
              :key="index"
              class="dialog-table__tr table-tr"
            >
              <div
                v-for="(headerConfig,headerConfigIndex) in content?.tableHeaderConfig||[]"
                :key="`${headerConfig.prop}${headerConfigIndex}`"
                class="dialog-table__td table-td"
              >
                <div class="table-txt">
                  {{ item[headerConfig.prop] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <slot name="footer"></slot>
      <slot name="close">
      </slot>
    </div>
  </div>
</template>
<script>
import DOMPurify from 'dompurify'

export default {
  name: 'TableContent',
  props: {
    lang: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({ }),
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      DOMPurify,
    }
  },
}
</script>
<style lang='scss'></style>
