<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="dialog-toast dialog-wrap"
  >
    <div
      class="dialog-box__animation dialog-content__box"
      @click.stop
    >
      <div class="dialog-box__body">
        <slot name="content">
          <div
            v-if="!allowHtml"
          >
            {{ content }}
            xxxx
          </div>
          <div
            v-else
            v-html="DOMPurify.sanitize(content)"
          ></div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import DOMPurify from 'dompurify'
export default {
  props: {
    duration: Number,
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      DOMPurify,
    }
  },
}
</script>
