<template>
  <div class="dialog-image__horizontal dialog-wrap">
    <div
      class=" dialog-image__horizontal__box  dialog-box__animation dialog-content__box"
      @click.stop
    >
      <slot name="title">
      </slot>
      <div class="dialog-image__horizontal__box-list dialog-box__body">
        <div
          v-for="(item,index) in content"
          :key="index"
          class="dialog-image__horizontal__box-item"
          :class="{'is-active':current === index}"
          @click="tabChange(index)"
        >
          <div class="dialog-image__horizontal__box-describe">
            {{ item.giftName }}<span>{{ item.gitTips }}</span>
          </div>
          <div class="dialog-image__horizontal__box-image">
            <img :src="item.imgSrc" />
          </div>
        </div>
      </div>
      <slot name="footer"></slot>
      <slot name="close">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    lang: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: 0,
    }
  },
  methods: {
    tabChange(idx) {
      this.current = idx
      this.$emit('changeImageContent', idx)
    },
  },

}
</script>
