<template>
  <div class="dialog-picture dialog-wrap">
    <div
      class="dialog-picture__box dialog-box__animation dialog-content__box"
      @click.stop
    >
      <slot name="title">
      </slot>
      <div class="dialog-box__body">
        <div class="dialog-picture__box-describe">
          {{ lang.pictureDesc }}
        </div>
        <div class="dialog-picture__box-image">
          <img :src="content" />
        </div>
      </div>
      <div class="dialog-picture__box-button">
        {{ lang.bottomDesc }}
      </div>
      <slot name="footer"></slot>

      <slot name="close">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SuperRewardContent',
  props: {
    title: {
      type: String,
      default: '',
    },
    lang: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: String,
      default: '',
    },
  },
}

</script>
