<template>
  <div class="dialog-image__portrait dialog-wrap">
    <div
      class="dialog-image__portrait__box dialog-box__animation dialog-content__box"
      @click.stop
    >
      <slot name="title">
      </slot>
      <div class="dialog-image__portrait__box-content dialog-box__body">
        <div
          v-for="(item,index) in content"
          :key="index"
          class="dialog-image__portrait__box-item"
        >
          <div class="dialog-image__portrait__box-label">
            {{ item.label }}
          </div>
          <div class="dialog-image__portrait__box-image">
            <img :src="item.imgSrc" />
          </div>
        </div>
      </div>
      <slot name="footer"></slot>

      <slot name="close">
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RewardDetailContent',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
    lang: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
