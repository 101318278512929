import Vue from 'vue'
import { version } from '../package.json'
import EventDialog from './packages/eventDialog'

const components = [EventDialog]

const install = Vue => {
  if (install.installed) {
    return
  }
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export { EventDialog }

export default {
  version,
  install,
}

export class EventDialogClass {
  // 参数各个含义可以查看 dialog 内部的prop字段解释
  constructor({ content, componentName, allowHtml, lang, showFooter, closeOnClickOverlay, lockScroll, customClass, showHeader, getContainer = () => document.body, theme, destroyOnClose }) {
    this.props = { content, componentName, allowHtml, lang, showFooter, closeOnClickOverlay, lockScroll, customClass, getContainer, theme, visible: true, showHeader, destroyOnClose }
    Array.isArray(EventDialogClass.instances) && EventDialogClass.instances.push(this)
  }

  // 获取当前的实例
  static getInstance({ content, componentName, allowHtml, lang, showFooter, closeOnClickOverlay, lockScroll, customClass, getContainer = () => document.body, theme, destroyOnClose }) {
    if (!EventDialogClass.instance) {
      EventDialogClass.instance = new EventDialogClass({ content, componentName, allowHtml, lang, showFooter, closeOnClickOverlay, lockScroll, customClass, getContainer, theme, destroyOnClose })
    }
    return EventDialogClass.instance
  }

  // 弹出弹窗
  alert(config = { }) {
    const { onConfirm, onClose, onClosed, onOpen, onOpened } = config
    const dialogComponent = new Vue({
      render: h =>
        h(EventDialog, {
          ref: 'eventDialog', // 绑定 ref
          props: Vue.observable(this.props),
          on: {
            confirm: (data = {}) => {
              typeof onConfirm === 'function' && onConfirm(data)
              !onConfirm && this.resolve(true)
            },
            // 弹窗开启
            open: () => {
              typeof onOpen === 'function' && onOpen()
            },
            // 弹窗开启动画结束
            opened: () => {
              typeof onOpened === 'function' && onOpened()
            },
            // 关闭弹窗
            close: () => {
              typeof onClose === 'function' && onClose()
            },
            // 关闭弹窗动画结束
            closed: () => {
              this.reject(false)
              typeof onClose === 'function' && onClosed()
              this.props.destroyOnClose && this.dialogComponent.$destroy()
              try {
                this.props.destroyOnClose && this.props.getContainer().removeChild(this.dialogComponent.$el)
              } catch (error) {
                console.log(error, `${this.props.getContainer()}元素下没有找到对应的弹窗实例`)
              }
            },
          },
        }),
    })

    const promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      dialogComponent.$mount()
      document.body.appendChild(dialogComponent.$el)
    })
    this.dialogComponent = dialogComponent
    return promise
  }

  // 调用中间状态
  done() {
    this.resolve()
  }

  // 主动关闭弹窗
  close() {
    this.dialogComponent.$refs.eventDialog && this.dialogComponent.$refs.eventDialog.closeDialog()
  }

  // 关闭所有弹窗
  static closeAllDialog() {
    Array.isArray(EventDialogClass.instances) && EventDialogClass.instances.forEach(instance => {
      instance.close()
    })
  }

  // 设置属性值，可以响应式
  setData(key, data) {
    this.props[key] = data
  }
}

window.EventDialogClass = EventDialogClass

EventDialogClass.instances = []

const DialogTypeEnum = {
  /** 通用 */
  COMMON: 'CommonContent',
  /** 表格 */
  TABLE: 'TableContent',
  /** 图片列表 */
  IMAGE_LIST_PORTRAIT: 'ImageListPortraitContent',
  /** 普通提示 */
  TIPS: 'TipsContent',
  /** 超级奖励 */
  PICTURE_DETAILS: 'PictureDetailsContent',
  /** 图片列表-横向  */
  IMAGE_LIST_HORIZONTAL: 'ImageListHorizontalContent',
  /** Toast */
  TOAST: 'ToastContent',
}

window.DialogTypeEnum = DialogTypeEnum
