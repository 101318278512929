<template>
  <div v-if="showDialog">
    <div
      v-show="isOpen"
      class="dialog-overlay dialog-wrap__event"
      :class="customClass"
      :data-theme="theme"
      :style="{'z-index':dialogZIndex}"
      @click.stop="clickOverlay"
    >
      <!-- 根据 componentName 条件渲染对应组件 -->
      <template v-if="componentName">
        <component
          :is="componentName"
          :content="content"
          :allow-html="allowHtml"
          :lang="lang"
          :show-header="showHeader"
          @close="closeDialog"
          @confirm="confirm"
          @changeImageContent="changeImageContent"
        >
          <template #title>
            <div class="dialog-box__header">
              <slot name="title">
                <div class="dialog-box__title">
                  {{ lang.title }}
                </div>
              </slot>
            </div>
          </template>
          <template #content>
            <slot name="content"></slot>
          </template>
          <template #tableDesc>
            <slot name="tableDesc"></slot>
          </template>
          <template #close>
            <div
              class="dialog-box__close"
              @click="closeDialog"
            ></div>
          </template>
          <template #footer>
            <div
              v-if="lang.cancelText||lang.confirmText||$slots.footer"
              class="dialog-box__footer"
            >
              <slot name="footer">
                <button
                  v-if="lang.cancelText"
                  class="dialog-btn dialog-btn__cancel"
                  @click="closeDialog"
                >
                  <span
                    data-txt
                    class="txt"
                  >{{ lang.cancelText }}</span>
                </button>
                <button
                  v-if="lang.confirmText"
                  class="dialog-btn dialog-btn__confirm"
                  @click="confirm"
                >
                  <span
                    data-txt
                    class="txt"
                  >{{ lang.confirmText }}</span>
                </button>
              </slot>
            </div>
          </template>
        </component>
      </template>
    </div>
  </div>
</template>

<script>
import CommonContent from './components/CommonContent.vue'
import ImageListHorizontalContent from './components/ImageListHorizontalContent.vue'
import ImageListPortraitContent from './components/ImageListPortraitContent.vue'
import PictureDetailsContent from './components/PictureDetailsContent.vue'
import TableContent from './components/TableContent.vue'
import TipsContent from './components/TipsContent.vue'
import ToastContent from './components/ToastContent.vue'

const DialogTypeEnum = {
  /** 通用 */
  COMMON: 'CommonContent',
  /** 表格 */
  TABLE: 'TableContent',
  /** 图片列表 */
  IMAGE_LIST: 'ImageList',
  /** 普通提示 */
  TIPS: 'TipsContent',
  /** 超级奖励 */
  PICTURE_DETAILS: 'PictureDetailsContent',
  /** 图片列表-横向  */
  IMAGE_LIST_HORIZONTAL: 'ImageListHorizontalContent',
  /** Toast */
  TOAST: 'ToastContent',
}
export default {
  name: 'EventDialog',
  components: {
    CommonContent,
    ImageListHorizontalContent,
    ImageListPortraitContent,
    PictureDetailsContent,
    TableContent,
    TipsContent,
    ToastContent,
  },
  model: {
    prop: 'visible',
    event: 'changeVisible',
  },
  props: {
    // 控制是否展示
    visible: {
      type: Boolean,
      default: false,
    },
    // 弹窗类型
    componentName: {
      type: String,
      default: DialogTypeEnum.COMMON,
    },
    // 数据内容，可能是表格数据、一段文本、一段html
    // eslint-disable-next-line
      content: null,
    // 是否渲染html 为 true 的情况则使用v-html 渲染message字段
    allowHtml: {
      type: Boolean,
      default: false,
    },
    // 多语言字段
    lang: {
      type: Object,
      default: () => ({}),
    },
    // 点击遮罩层关闭
    closeOnClickOverlay: {
      type: Boolean,
      default: false,
    },
    // 是否锁定屏幕
    lockScroll: {
      type: Boolean,
      default: false,
    },
    // 自定义弹窗最外层类名
    customClass: {
      type: String,
      default: null,
    },
    // 元素要挂载的位置
    getContainer: {
      type: Function,
      default: () => document.body,
    },
    // 主题
    theme: {
      type: String,
      default: null,
    },
    // 遮罩层、弹窗初始层级
    zIndex: {
      type: Number,
      default: 1000,
    },
    // 是否显示表头，仅表格弹窗有用
    showHeader: {
      type: Boolean,
      default: true,
    },
    // 关闭弹窗时是否销毁元素
    destroyOnClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
      imageContentIndex: 0, // 获取图片切换时的索引值
      dialogZIndex: 0, // 弹窗内容的索引值
      isDoingCloseAnimate: false, // 是否正在执行关闭操作，防止冲突
      showDialog: false, // 因为只用v-show来控制的话会存在一个问题：引入时界面就存在节点，因此需要一个属性来控制
    }
  },
  watch: {
    visible: {
      handler: function(val, oldVal) {
        val && (this.isOpen = val)
        val && (this.showDialog = val)
        val && this.openDialog()
        // 当值为false时，调用关闭弹窗的方法，因为还有动画需要执行
        if (!val && oldVal) {
          this.closeDialog()
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 打开弹窗判断是否需要添加遮罩层
    judgeOpenMask() {
      const eventDialogMaskEl = document.getElementById('event-dialog-mask')
      // 判断是否存在遮罩层
      if (eventDialogMaskEl) {
        // 如果存在，那么需要设置一下遮罩层的层级，因为层级需要大于上一个弹窗的层级，才可以真正有效遮住
        const zIndex = Number(eventDialogMaskEl.style.zIndex) + 5
        eventDialogMaskEl.style.zIndex = zIndex
        this.dialogZIndex = zIndex + 1
      } else {
        // 如果不存在的情况，那么就创建一个弹窗出来
        const maskElement = document.createElement('div')
        // 设置类名
        maskElement.className = 'event-dialog__mask'
        // 设置 ID
        maskElement.id = 'event-dialog-mask'
        maskElement.classList.add('dialog-wrap__animation-open')
        maskElement.style.zIndex = this.zIndex
        this.dialogZIndex = this.zIndex + 1
        document.body.appendChild(maskElement)
      }
    },

    // 关闭弹窗判断是否需要移除遮罩层
    judgeCloseMask() {
      const eventDialogEls = document.getElementsByClassName('dialog-wrap__event')
      const eventDialogMaskEl = document.getElementById('event-dialog-mask')
      // 如果在执行关闭动画前只有一个弹窗，那么这边也是需要执行关闭动画了
      if (eventDialogEls.length && eventDialogEls.length === 1) {
        eventDialogMaskEl.classList.remove('dialog-wrap__animation-open')
        eventDialogMaskEl.classList.add('dialog-wrap__animation-close')
      }
    },

    // 是否需要移除遮罩层
    judgeRemoveMask() {
      this.$nextTick(() => {
        const eventDialogEls = document.getElementsByClassName('dialog-wrap__event')
        const eventDialogMaskEl = document.getElementById('event-dialog-mask')
        const eventDialogLength = eventDialogEls.length
        let allNotShow = true // 是否全部为关闭
        for (let i = 0; i < eventDialogLength; i++) {
          if (eventDialogEls[i].style.display !== 'none') {
            allNotShow = false
            break
          }
        }
        // 判断是否还有弹窗元素，不存在弹窗的话就将其移出
        if (!eventDialogLength || allNotShow) {
          eventDialogMaskEl && document.body.removeChild(eventDialogMaskEl)
        } else {
          // 获取当前弹窗的最高层级，如果存在的话，就获取当前所有元素中最高层级的元素，并修改遮罩层的层级
          const maxZIndex = Array.from(eventDialogEls).reduce(function(max, obj) {
            return obj.style.display !== 'none' && Number(obj.style.zIndex) > max ? Number(obj.style.zIndex) : max
          }, -Infinity)
          // 遮罩层的层级-1
          eventDialogMaskEl.style.zIndex = maxZIndex - 1
        }
      })
    },

    // 打开弹窗执行的回调
    openDialog() {
      this.judgeOpenMask()
      // 获取容器元素
      let container = document.body
      if (typeof this.getContainer === 'function') {
        // 创建弹窗组件的根元素
        container = this.getContainer() || document.body
      }
      this.$emit('open')
      this.onLockScroll()
      this.$nextTick(() => {
        this.$el && container.appendChild(this.$el)
        this.openAnimate()
      })
    },

    // 界面打开时的动画
    openAnimate() {
      const element = this.$el.querySelector('.dialog-box__animation')
      let startTime
      const that = this
      function animate(timestamp) {
        if (!startTime) {
          startTime = timestamp
        }

        const progress = timestamp - startTime
        const duration = 150 // 设置动画时长为100毫秒

        // 根据时间进度计算动画的状态
        const scale = Math.min(progress / duration, 1)

        // 应用动画状态
        element.style.transform = `scale3d(${scale},${scale},${scale})`
        element.style.opacity = scale
        if (progress < duration) {
          requestAnimationFrame(animate)
        } else {
          that.$emit('opened')
        }
      }
      requestAnimationFrame(animate)
    },

    // 关闭弹窗的动画
    closeAnimate() {
      if (this.isDoingCloseAnimate) return
      this.$emit('close') // 关闭弹窗的回调
      let startTime
      const that = this
      const element = that.$el.querySelector('.dialog-box__animation')
      that.isDoingCloseAnimate = true
      function closeAnimate(timestamp) {
        if (!startTime) startTime = timestamp
        const elapsed = timestamp - startTime

        // 根据时间计算动画的进度
        const progress = Math.min(elapsed / 150, 1) // 动画时长为100毫秒

        // 根据进度更新元素的样式
        const scale = 1 - progress // 从1到0的缩放
        element.style.transform = `scale3d(${scale},${scale},${scale})`
        element.style.opacity = scale

        // 判断动画是否结束
        if (progress < 1) {
          requestAnimationFrame(closeAnimate)
        } else {
          // 动画结束后执行的操作
          that.isOpen = false
          that.$emit('changeVisible', false) // v-model 会用到的属性
          // 动画已经完成
          setTimeout(() => {
            that.isDoingCloseAnimate = false
            that.$emit('closed')
            if (that.destroyOnClose) {
              that.$el.parentNode && that.$el.parentNode.removeChild(that.$el)
              that.showDialog = false
            }
          }, 100)
          // 弹窗关闭时恢复滚动
          that.onLockScroll()
          that.judgeRemoveMask()
        }
      }
      // 启动动画
      requestAnimationFrame(closeAnimate)
      that.judgeCloseMask()
    },

    // 关闭弹窗执行的回调
    closeDialog() {
      this.closeAnimate()
    },

    // 点击确定的方法 点击确定的情况不论什么方式使用组件，务必要主动关闭弹窗，否则无法自动关闭
    confirm(data) {
      if (this.componentName === DialogTypeEnum.IMAGE_LIST_HORIZONTAL) {
        data = { current: this.imageContentIndex }
      }
      this.$emit('confirm', data)
    },

    // 点击遮罩层方法
    clickOverlay() {
      this.closeOnClickOverlay && this.closeDialog()
    },

    // 锁定滚动条；防止打开时界面抖动
    onLockScroll() {
      if (this.lockScroll) {
        if (this.isOpen) {
          // 获取当前滚动条的宽度
          const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth
          // 给界面加一个对应滚动条宽度的padding  防止抖动
          document.body.style.paddingRight = scrollBarWidth + 'px'
          // 使界面不可以滚动
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.paddingRight = ''
          document.body.style.overflow = 'auto'
        }
      }
    },

    // 图片可选的组件内部方法
    changeImageContent(val) {
      this.imageContentIndex = val
      this.$emit('changeImageContent', val)
    },
  },
}
</script>

<style lang="scss">
  @import './dialog.scss';
  @keyframes openGradientAnimation {
    0% {
      background-color: rgba(0, 0, 0, 0); /* 初始背景色 */
    }

    100% {
      background-color: var(--bg-dialog-mask,rgba(0, 0, 0, 0.5)); /* 最终背景色 */
    }
  }

  @keyframes closeGradientAnimation {
    0% {
      background-color:var(--bg-dialog-mask,rgba(0, 0, 0, 0.5)); /* 初始背景色 */
    }

    100% {
      background-color: rgba(0, 0, 0, 0); /* 最终背景色 */
    }
  }
</style>

<style lang="scss">
  //有新的主题就往这里引入
  @import './theme/lm.scss';
  @import './theme/lo.scss';
</style>
