<template>
  <div class="dialog-tips dialog-wrap">
    <div
      class="dialog-tips__box dialog-box__animation dialog-content__box"
      @click.stop
    >
      <div class="dialog-tips__box-content dialog-box__body">
        {{ content }}
      </div>
      <slot name="footer"></slot>
      <slot name="close">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TipsContent',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
}
</script>

<script setup>

</script>
